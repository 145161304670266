import React, {useState} from 'react';
import './css/style.css'; 
import './css/style.css.map'; 
// import './css/style.scss'; 
import './css/bootstrap.css'; 
import './css/responsive.css'; 
// import './css/font-awesome.min.css'; 
import Swal from 'sweetalert2'; // Import SweetAlert2

import 'bootstrap/dist/css/bootstrap.css';



// import './js/bootstrap.js'; 
// import './js/custom.js'; 
// import './js/jquery-3.4.1.min.js'; 
// import './js/particles.js'; 

import axios from 'axios';


function App() {
  // JavaScript functions can be defined here
  function popupPhone() {
    alert("+1 (206) 451-3650");
    // You can replace the 'alert' with custom code to achieve the desired effect.
        // For example, you can use a library like SweetAlert2 for a nicer popup
    // Replace this with your preferred popup library or custom code
    // Swal.fire({
    //   title: 'Call Us',
    //   text: `Would you like to call ${phoneNumber}?`,
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonText: 'Call',
    //   cancelButtonText: 'Cancel',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     // You can implement code here to initiate a call
    //     // For web applications, you might open a link with a tel: protocol
    //     window.location.href = `tel:${phoneNumber}`;
    //   }
    // });
  }
  // Define states to capture form input
  const [companyName, setCompanyName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  // ...

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Make a POST request to the server with form data
      const response = await axios.post('/sendEmail', {
        company_name: companyName,
        customer_email: customerEmail,
        phone_number: phoneNumber,
        message: message,
      });
  
      // Handle success or display a confirmation message
      if (response.status === 200) {
        alert('Email sent successfully!');
      } else {
        alert('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    };
  
      
  }


  return (
    <div className="App">
      {/* Header section */}
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <a className="navbar-brand" href="/">
              <img src="/images/QAF.png" alt="QAF Logo" />
              <span>
                QAF <span style={{ color: 'orange' }}>Services</span>
              </span>
            </a>

            {/* <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button> */}

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                {/* Uncomment the lines below if you have other navigation links */}
                {/* <li className="nav-item">
                  <a className="nav-link" href="/about">About</a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="#service">
                    Services
                  </a>
                </li>
              </ul>
              <div className="quote_btn-container">
                <a href="">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  {/* <span onClick={popupPhone}>Call +1 (206) 451-3650</span> */}
                  <span>Call +1 (206) 451-3650</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* End header section */}

      {/* Slider section */}
      <section className="slider_section">
        <div id="customCarousel1" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>
                        QAF <br />
                        <span style={{ color: 'orange' }}>
                          <h2>Services</h2>
                        </span>
                      </h1>
                      <p>
                        Improve cleanliness and transform your workplace<br />
                        with the help of QAF Services.
                      </p>
                      <div className="btn-box">
                        {/* <a href="#letstalk" className="btn-1">
                          Let's Talk
                        </a> */}
                        <a href="#service" className="btn-2">
                          Our Services
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-lg-10 mx-auto">
                        <div className="img-box">
                          <img
                            src="images/background_img-removebg.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End slider section */}

      {/* About section */}
      <section className="about_section layout_padding-bottom">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>About Us</h2>
          </div>
        </div>
        <div className="container">
          <div className="heading_container heading_center">
            <p>
              Welcome to QAF Services, your trusted partner for maintaining
              <br /> impeccably clean work environments in the vibrant city of Seattle. At QAF Services, <br />
              we are dedicated to delivering top-tier commercial cleaning solutions designed to meet the unique
              <br /> needs of businesses and organizations across the Seattle region. As a dynamic and emerging leader in the industry, <br />
              we're passionate about enhancing workplace cleanliness and hygiene, contributing to healthier and more <br />
              productive spaces. Our team of skilled cleaning professionals is equipped with state-of-the-art <br />
              equipment and eco-friendly cleaning products to uphold the highest standards of cleanliness. <br />
              Whether you oversee a corporate office, healthcare facility, educational institution, <br />
              or any other commercial space in Seattle, we have the expertise to serve you.<br />
            </p>
          </div>
        </div>
      </section>
      {/* End about section */}

      {/* Service section */}
      <section className="service_section layout_padding" id="service">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="img-box">
                  <img src="images/janitorial services.png" alt="Janitorial Services" />
                </div>
                <div className="detail-box">
                  <h4>Janitorial Services</h4>
                  <p>Comprehensive janitorial services that cover daily office cleaning</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="img-box">
                  <img src="images/specialized_cleaning.png" alt="Specialized Cleaning" />
                </div>
                <div className="detail-box">
                  <h4>Specialized Cleaning</h4>
                  <p>Tailored cleaning services to your specific industry</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="img-box">
                  <img src="images/services_img.png" alt="Customized Solutions" />
                </div>
                <div className="detail-box">
                  <h4>Customized Solutions</h4>
                  <p>Cleaning plans tailored to your specific needs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End service section */}

      {/* contact section */}
      {/* <section className="contact_section layout_padding-bottom" id="letstalk">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Get In Touch</h2>
          </div>
          <div className="row">
            <div className="col-md-8 col-lg-6 mx-auto">
              <div className="form_container">
                <form action="/sendEmail" method="post">
                  <div>
                    <input type="text" name="company_name" placeholder="Your Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)} />
                  </div>
                  <div>
                    <input type="email" name="customer_email" placeholder="Your Email Address" 
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)} />
                  </div>
                  <div>
                    <input type="text" name="phone_number" placeholder="Your Phone Number" 
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} />
                  </div>
                  <div>
                    <input type="text" name="message" className="message-box" placeholder="Tell us about what we can help you with." 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)} />
                  </div>
                  <div className="btn_box">
                    <button type="submit">SEND</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end contact section */}


      {/* Info section */}
      <section className="info_section layout_padding2">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="info_contact">
                <div className="contact_link_box">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>Seattle, WA</span>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    {/* <span onClick={popupPhone}>Call +1 (206) 451-3650</span> */}
                    <span>Call +1 (206) 451-3650</span>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span>contact@qafservices.com</span>
                </div>
              </div>
              <div className="info_social">
                <a href="https://www.linkedin.com/company/qafservices/">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 mb-0">
              <h4>Subscribe</h4>
              <form action="#">
                <input type="text" placeholder="Enter email" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* End info section */}

      {/* Footer section */}
      <footer className="footer_section">
        <div className="container">
          <p>
            &copy; <span id="displayYear"></span> All Rights Reserved By
            <a href="">
              <span> QAF <span style={{ color: 'orange' }}>Services</span></span>
            </a>
          </p>
        </div>
      </footer>
      {/* End footer section */}
    </div>
  );
}

export default App;
